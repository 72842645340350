<template>
  <h1>Bald verfügbar!</h1>
 <!-- <v-row class="justify-center">
    <v-col cols="12" sm="6" lg="4" v-for="plan of this.plans" :key="plan.title" class="plan">
      <v-card class="pa-9 card " :class="plan.cardClass">
        <v-icon size="50">
          {{ plan.icon }}
        </v-icon>
        <v-card-title class="justify-center">
          <h4 class="text-h6">{{ plan.title }}</h4>
        </v-card-title>
        <v-card-subtitle>
          <h5 class="text-subtitle-1">{{ plan.subtitle }}</h5>
        </v-card-subtitle>

        <v-card-text>
          <div style="">
            <v-list :class="plan.listClass">
              <v-list-item v-for="point of plan.bulletPoints" :key="point" class="text-start">
                <v-icon color="secondary" size="15" class="pe-2 ">
                  mdi-check-circle-outline
                </v-icon>
                {{ point }}
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>


        <div class="bottomFixedPlan justify-center">
          <p v-if="plan.available" class="text-h6 pt-3 justify-center">{{ plan.price }}</p>
          <v-card-actions class="justify-center">
            <v-btn class="primary white--text" v-if="plan.available" @click="$router.push('/registrieren')">
              Gratis Testen
            </v-btn>
            <p v-if="!plan.available">Bald verfügbar</p>
          </v-card-actions>
        </div>


      </v-card>
    </v-col>
  </v-row>-->
</template>

<script>
export default {
  name: "Plans",
  data() {
    return {
      plans: [
        {
          title: 'Essential',
          subtitle: 'Fang an professionell Hochzeiten zu planen',
          bulletPoints: ['Kunden und Dienstleister verwalten', 'Aktivitäten und Aufgaben', 'Budgetplaner'],
          icon: 'mdi-account',
          price: '14,97€ / Monat',
          available: false,
        },
        {
          title: 'Professional',
          subtitle: 'Alles was etablierte Planer und Agenturen brauchen',
          bulletPoints: ['Alle Funktionen aus dem Essential Plan', 'Mehr Benutzer fähig', 'Anbindung an externe Systeme (Kalender, Buchhaltung)', 'Designoptionen'],
          icon: 'mdi-account-multiple',
          available: false,
          price: '34,95€ / Benutzer / Monat',
          cardClass: 'midPlan',
          listClass: 'quaternary'
        },
        {
          title: 'Enterprise',
          subtitle: 'Individuell auf deine Bedürfnisse zugeschnitten',
          bulletPoints: ['An deine Prozesse angepasst', 'Individuelle Funktionen', 'Frei gestalt- und anpassbar'],
          price: '',
          icon: 'mdi-account-group',
          available: false
        }
      ]
    }
  }
}

</script>

<style scoped>
.plan {
  transition: 0.5s;
}

.card {
  min-height: 580px;
}

.plan:hover {
  transform: scale(1.07);
  transition: 0.5s;
}

.bottomFixedPlan {
  position: absolute;
  bottom: 50px;
  width: 100%;
  left:0

}

.midPlan {
  background-color: #EDE9D4;
}

</style>
